import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 5px;
  .zone-block {
    border-radius: 5px;
    .canvas-na {
      width: 65px;
      height: 45px;
      background: ${(props) => props.theme.darkBackground};
      border-radius: 5px;
    }
  }
`
