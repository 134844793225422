/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import ZonesPolygonStyled from './styledComponent'
import * as PIXI from 'pixi.js'

import { LIST_ZONE_COLORS } from '../../utils'

class ZonesPolygon extends React.PureComponent {
  constructor(props) {
    super(props)
    let applicationStage = undefined
    let containerImage = undefined
    let containerShape = undefined
  }

  componentDidMount() {
    this.createApplicationStage()
    this.createShapeContainer()
    this.geneRateZone()
    this.removePIXIConsole()
  }

  componentDidUpdate(prevProps) {
    this.geneRateZone()
  }

  componentWillUnmount() {
    this.applicationStage.destroy()
  }

  removePIXIConsole() {
    PIXI.utils.skipHello()
  }

  createApplicationStage() {
    const canvas = this.zone
    this.applicationStage = new PIXI.Application(65, 45, {
      autoResize: true,
      backgroundColor: 0x1f2d39,
      resolution: 1,
      antialias: true
    })
    canvas.appendChild(this.applicationStage.view)
  }

  createShapeContainer() {
    this.containerShape = new PIXI.Container(65, 45)
    this.containerShape.interactive = true
    this.applicationStage.stage.addChild(this.containerShape)
  }

  geneRateZone() {
    this.containerShape.removeChildren()
    const polygonGraphic = new PIXI.Graphics()
    const color = this.props.zoneData.grid_color ? this.props.zoneData.grid_color : LIST_ZONE_COLORS[this.props.zoneIterator]
    polygonGraphic.beginFill(color, 0.5)
    polygonGraphic.lineStyle(2, color, 1)
    polygonGraphic.interactive = false
    const coordinates = this.props.coordinates.map((coordinate) => {
      const ratioX = 65 / this.props.resolutionWidth
      const ratioY = 45 / this.props.resolutionHeight
      const x = coordinate.x * ratioX
      const y = coordinate.y * ratioY
      return new PIXI.Point(x, y)
    })

    polygonGraphic.drawPolygon(coordinates)
    polygonGraphic.endFill()
    this.containerShape.addChild(polygonGraphic)
  }

  render() {
    return (
      <ZonesPolygonStyled>
        <div className="zone-block">
          <div className={'canvas-na'} id={`zone-${this.props.zoneData.zone_id}`} ref={(zone) => (this.zone = zone)} />
        </div>
      </ZonesPolygonStyled>
    )
  }
}

ZonesPolygon.propTypes = {
  zoneIterator: PropTypes.number,
  zoneData: PropTypes.shape({
    allowed: PropTypes.bool,
    camera_id: PropTypes.number,
    grid_color: PropTypes.string,
    conditions: PropTypes.shape({
      any_features_present: PropTypes.arrayOf(
        PropTypes.shape({
          class_confidence: PropTypes.number,
          class_id: PropTypes.number,
          class_name: PropTypes.string
        })
      )
    }),
    features: PropTypes.arrayOf(
      PropTypes.shape({
        class_confidence: PropTypes.number,
        class_id: PropTypes.number,
        class_name: PropTypes.string,
        color_confidence: PropTypes.number,
        color_id: PropTypes.number,
        color_name: PropTypes.string
      })
    ),
    points: PropTypes.array,
    violation_id: PropTypes.number,
    violation_name: PropTypes.string,
    zone_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }),
  coordinates: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    })
  ).isRequired,
  resolutionWidth: PropTypes.number,
  resolutionHeight: PropTypes.number
}

export default ZonesPolygon
